define("discourse/plugins/discourse-verifiable-credentials/discourse/components/resource-list", ["exports", "@ember/component", "@ember/object/computed", "@popperjs/core", "@ember/runloop", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _core, _runloop, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("record.resources"), (_obj = {
    classNames: ["resource-list"],
    attributeBindings: ["record.resource.did:data-did"],
    showResources: false,
    single: (0, _computed.equal)("record.resources.length", 1),
    resource(resources) {
      return resources.length ? resources[0] : null;
    },
    didInsertElement() {
      $(document).on("click", event => this.documentClick(event));
    },
    willDestroyElement() {
      $(document).off("click", event => this.documentClick(event));
    },
    documentClick(event) {
      if (this._state === "destroying") {
        return;
      }
      if (!event.target.closest(`tr[data-created-at="${this.record.created_at}"] .resource-list button`)) {
        this.set("showResources", false);
        this._popper = null;
      }
    },
    createModal() {
      let container = this.element.querySelector(".list-container");
      let modal = this.element.querySelector(".list");
      this._popper = (0, _core.createPopper)(container, modal, {
        strategy: "absolute",
        placement: "bottom-start",
        modifiers: [{
          name: "preventOverflow"
        }, {
          name: "offset",
          options: {
            offset: [0, 5]
          }
        }]
      });
    },
    actions: {
      showResources() {
        this.toggleProperty("showResources");
        if (this.showResources) {
          (0, _runloop.scheduleOnce)("afterRender", this, this.createModal);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "resource", [_dec], Object.getOwnPropertyDescriptor(_obj, "resource"), _obj)), _obj)));
});