define("discourse/plugins/discourse-verifiable-credentials/discourse/components/resource-label", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/lib/url", "I18n"], function (_exports, _component, _decorators, _url, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("resource.type", "model.name", "resource.verified"), _dec2 = (0, _decorators.default)("resource.type"), _dec3 = (0, _decorators.default)("resource.verified"), _dec4 = (0, _decorators.default)("resource.type", "resource.id"), (_obj = {
    classNameBindings: [":resource-label", "resource.type", "resource.verified:verified"],
    attributeBindings: ["title"],
    title(type, name, verified) {
      const key = verified ? "verified" : "not_verified";
      return _I18n.default.t(`verifiable_credentials.user.records.resources.${type}.${key}`, {
        name
      });
    },
    resourceIcon(type) {
      return {
        group: "users",
        badge: "certificate"
      }[type];
    },
    verifiedIcon(verified) {
      return verified ? "check" : "times";
    },
    model(type, id) {
      if (type === "group") {
        const groups = this.site.credential_groups;
        return groups.find(g => g.id === id);
      }
      if (type === "badge") {
        const badges = this.currentUser.verifiable_credential_badges;
        return badges.find(b => b.id === id);
      }
    },
    click() {
      const resource = this.resource;
      const model = this.model;
      let url = "#";
      if (resource.type === "group") {
        url = `/g/${model.name}`;
      }
      if (resource.type === "badge") {
        url = `/badges/${model.id}/${model.name}`;
      }
      _url.default.routeTo(url);
    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_dec], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "resourceIcon", [_dec2], Object.getOwnPropertyDescriptor(_obj, "resourceIcon"), _obj), _applyDecoratedDescriptor(_obj, "verifiedIcon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "verifiedIcon"), _obj), _applyDecoratedDescriptor(_obj, "model", [_dec4], Object.getOwnPropertyDescriptor(_obj, "model"), _obj)), _obj)));
});