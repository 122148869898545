define("discourse/plugins/discourse-verifiable-credentials/discourse/components/verifiable-credentials-group-button", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("group.custom_fields.verifiable_credentials_allow_membership", "group.is_group_user"), _dec2 = (0, _decorators.default)("canAccess", "group.custom_fields.verifiable_credentials_show_button"), (_obj = {
    canAccess(membershipByVC, userIsGroupUser) {
      return membershipByVC && !userIsGroupUser;
    },
    showButton(canAccess, showButton) {
      return canAccess && showButton;
    },
    resources() {
      let resources = [{
        type: "group",
        id: this.group.id
      }];
      return resources;
    }
  }, (_applyDecoratedDescriptor(_obj, "canAccess", [_dec], Object.getOwnPropertyDescriptor(_obj, "canAccess"), _obj), _applyDecoratedDescriptor(_obj, "showButton", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showButton"), _obj), _applyDecoratedDescriptor(_obj, "resources", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "resources"), _obj)), _obj)));
});