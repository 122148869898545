define("discourse/plugins/discourse-verifiable-credentials/discourse/controllers/verifiable-credentials-presentation", ["exports", "@ember/controller", "discourse-common/utils/decorators", "@ember/string", "discourse/lib/ajax", "I18n"], function (_exports, _controller, _decorators, _string, _ajax, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("siteSettings.verifiable_credentials_provider"), (_obj = {
    loadingQRCode: false,
    hasQRCode: true,
    perform() {
      const provider = this.siteSettings.verifiable_credentials_provider;
      this[(0, _string.camelize)(provider)]();
    },
    description(provider) {
      return _I18n.default.t(`verifiable_credentials.present.provider.${provider}.description`);
    },
    mattr() {
      this.set("loadingQRCode", true);
      (0, _ajax.ajax)("/vc/presentation/mattr/create", {
        type: "POST",
        data: {
          resources: this.model.resources,
          provider: "mattr"
        }
      }).then(result => {
        this.set("loadingQRCode", false);
        if (result.success) {
          const domain = this.siteSettings.verifiable_credentials_verifier_domain;
          this.set("QRData", `didcomm://${domain}/?request=${result.jws}`);
        }
      });
    },
    verifiableCredentialsLtd() {
      const user = this.currentUser;
      const discourseUrl = window.location.protocol + "//" + window.location.hostname;
      const domain = this.siteSettings.verifiable_credentials_verifier_domain;
      const data = {
        sp: discourseUrl,
        vcVerifier: domain,
        authnCreds: {
          user_id: user.id,
          resources: this.model.resources
        },
        policyMatch: {
          type: this.credentialId
        },
        returnResults: [{
          internet: `${discourseUrl}/vc/verify/verifiable_credentials_ltd`
        }]
      };
      const encodedRequest = btoa(JSON.stringify(data)).replace(/\+/g, "-").replace(/\//g, "_").replace(/\=+$/, "");
      this.set("QRData", encodedRequest);
    }
  }, (_applyDecoratedDescriptor(_obj, "description", [_dec], Object.getOwnPropertyDescriptor(_obj, "description"), _obj)), _obj)));
});